<template>
  <div class="form-cont">
    <div class="product-title">Edit Product</div>
    <div class="form-product">
      <a-form ref="formRef" size="medium" :model="form" layout="vertical" @submitSuccess="validateSuccess">
        <a-card title="Base Information">
          <template #extra>
            <a-space>
              <span>Avaliable:</span>
              <a-switch checked-color="#13B966" unchecked-color="#F2F3F5" v-model="form.status" :checked-value="1"
                :unchecked-value="2">
                <template #checked> ON </template>
                <template #unchecked> OFF </template>
              </a-switch>
            </a-space>
          </template>
          <a-row :gutter="[20, 20]">
            <a-col :span="12">
              <a-form-item field="sku" label="SKU" :rules="[
                {
                  required: true,
                  message: 'SKU is required'
                },
                {
                  minLength: 2, maxLength: 40, meaasge: '2-40 characters'
                }
              ]">
                <a-input v-model="form.sku" placeholder="Required 2-40 characters" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item field="name" label="Name" :rules="[
                {
                  minLength: 2, maxLength: 50, meaasge: '2-50 characters'
                }
              ]">
                <a-input v-model="form.name" placeholder="Optional 2-50 characters" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item field="description" label="Description" :rules="[
            {
              minLength: 1, maxLength: 200, meaasge: '1-200 characters'
            }
          ]">
            <a-textarea v-model="form.description" placeholder="Optional 1-200 characters" />
          </a-form-item>
        </a-card>
        <div class="title">Shipment Information</div>
        <a-row :gutter="[10, 10]" class="shipment">
          <a-col :span="9">
            <a-form-item label="Dimension(in)" required :content-flex="true" :merge-props="false">
              <a-row :gutter="[10, 0]">
                <a-col :span="8">
                  <a-form-item field="length" hide-label :rules="[
                    {
                      required: true,
                      message: 'required'
                    },
                    { type: 'number', max: 108, min: 1, message: '1-108' }
                  ]">
                    <a-input v-model="form.length" placeholder="L">
                      <template #suffix>
                        In
                      </template>
                    </a-input>
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item field="width" hide-label :rules="[
                    {
                      required: true,
                      message: 'required'
                    },
                    { type: 'number', max: 108, min: 1, message: '1-108' }
                  ]">
                    <a-input v-model="form.width" placeholder="W">
                      <template #suffix>
                        In
                      </template>
                    </a-input>
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item field="height" hide-label :rules="[
                    {
                      required: true,
                      message: 'required'
                    },
                    { type: 'number', max: 108, min: 1, message: '1-108' }
                  ]">
                    <a-input v-model="form.height" placeholder="H">
                      <template #suffix>
                        In
                      </template>
                    </a-input>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="Weight" :content-flex="true">
              <a-row :gutter="[20, 0]">
                <a-col :span="12">
                  <a-form-item field="weight_lb" hide-label :rules="weightRules.weight_lb">
                    <a-input v-model="form.weight_lb">
                      <template #suffix>
                        Lb
                      </template>
                    </a-input>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item field="weight_oz" hide-label :rules="weightRules.weight_oz">
                    <a-input v-model="form.weight_oz">
                      <template #suffix>
                        Oz
                      </template>
                    </a-input>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item field="declaredValue" label="Declared Value">
              <a-input v-model="form.declaredValue" placeholder="Optional">
                <template #prefix>
                  $
                </template>
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <div class="btn">
          <a-space>
            <a-popconfirm content="Are you sure you want to reset?" type="warning" class="warnPop" @ok="resetForm"
              ok-text="Reset" cancel-text="Cancel">
              <a-button shape="round">Reset</a-button>
            </a-popconfirm>
            <a-button type="primary" shape="round" html-type="submit">submit</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, watch } from 'vue'
import { productEdit, productDetail } from '@/api/product.js'
import { Notification } from '@arco-design/web-vue'
import { useRoute } from 'vue-router'
import router from '../../router'
// 使用loading
import { load } from '@/utils/loading.js'
const route = useRoute()
let editId = route.query.editId
console.log(editId)
const formRef = ref(null)
const form = reactive({
  sku: '',
  status: 1,
  name: '',
  description: '',
  length: '',
  width: '',
  height: '',
  weight_lb: '',
  weight_oz: '',
  declaredValue: '',
  product_length: '',
  product_width: '',
  product_height: '',
  product_weight: ''
})
// 重置
const resetForm = () => {
  formRef.value.resetFields()
}
// 提交
const validateSuccess = async () => {
  load.show('submitting...')
  const msg = await productEdit({
    id: editId,
    sku: form.sku,
    name: form.name,
    description: form.description,
    status: form.status,
    length: form.length,
    width: form.width,
    height: form.height,
    weight: form.weight_lb,
    weight2: form.weight_oz,
    insurance: form.declaredValue,
    product_length: form.product_length,
    product_width: form.product_width,
    product_height: form.product_height,
    product_weight: form.product_weight
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: msg.message
    })
    router.push('/products/productslist')
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// 获取product回填详情
const getProductDetail = async () => {
  load.show('Loading ...')
  const msg = await productDetail({
    id: editId
  })
  if (msg.code === 0) {
    load.hide()
    form.sku = msg.data.sku
    form.name = msg.data.name
    form.description = msg.data.description
    form.status = msg.data.status
    form.length = msg.data.length
    form.width = msg.data.width
    form.height = msg.data.height
    form.weight_lb = msg.data.weight
    form.weight_oz = String(msg.data.weight2)
    form.declaredValue = msg.data.insurance
  }
}
// 修改product 回填
if (editId) {
  getProductDetail()
}
// 计算 长 宽 高
let moreDimension = false
watch(() => form, (newVal) => {
  let l = Number(newVal.length)
  let w = Number(newVal.width)
  let h = Number(newVal.height)
  let measure = 0
  let max = Math.max(l, w, h)
  if (max === l) {
    measure = l + w * 2 + h * 2
  } else if (max === w) {
    measure = w + l * 2 + h * 2
  } else {
    measure = h + l * 2 + w * 2
  }
  if (moreDimension !== true) {
    if (measure > 165) {
      Notification.error({
        id: 'dimensionProduct',
        title: 'Dimension limit!',
        duration: 0,
        content: 'The amount of length + width*2 + height*2 is no more than 165.',
        closable: true
      })
      moreDimension = true
    }
  }
  if (measure <= 165) {
    Notification.remove('dimensionProduct')
    moreDimension = false
  }
}, { immediate: true, deep: true })
// 验证weight
const weightRules = {
  weight_lb: [
    {
      validator: (value, cd) => {
        let weight1 = Number(value)
        let weightOz = Number(form.weight_oz)
        if (weight1 + weightOz === 0) {
          cd(new Error('Required').message)
        } else if (weight1 + (weightOz / 16) > 150) {
          cd(new Error('Total weight can not exceed 150 lb').message)
        }
      }
    }
  ],
  weight_oz: [
    {
      validator: (value, cd) => {
        let weight2 = Number(value)
        let weightLb = Number(form.weight_lb)
        if (weight2 + weightLb === 0) {
          cd(new Error('Required').message)
        } else if (weight2 + weightLb * 16 > 2400) {
          cd(new Error('Total weight can not exceed 2400 oz').message)
        }
      }
    }
  ]
}

</script>
<style lang="less" scoped>
.product-title {
  margin: 18px 24px 18px 48px;
  font-weight: 500;
  font-size: 20px;
  color: var(--color-text-1);
}

.form-product {
  margin: 0 300px;
}

.title {
  margin: 24px 0 36px 0;
  font-weight: 500;
  font-size: 16px;
  color: var(--color-text-1);
}

.shipment :deep(.arco-form-item-label-col) {
  padding-bottom: 8px;
  border-bottom: 1px solid var(--color-border-2);
}

.btn {
  text-align: right;
}

:deep(.arco-form-item-label-required-symbol) {
  display: none;
}
</style>
<style>
.warnPop .arco-popconfirm-popup-content .arco-popconfirm-footer .arco-btn-primary {
  color: rgb(var(--warning-6));
  background-color: rgb(var(--warning-1));
}
</style>
